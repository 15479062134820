<template>
  <page-header-wrapper :title="false">
    <a-card :bordered="false">
      <a-back-top />
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="任务名称">
                <a-input placeholder="请输入任务名称" v-model="queryParam.title" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="任务状态">
                <a-select
                  v-model="queryParam.status"
                  placeholder="请选择状态"
                  allow-clear
                >
                  <a-select-option :key="-1" :value="-1">请选择状态</a-select-option>
                  <a-select-option v-for="item in statusList" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="任务优先级">
                <a-select
                  v-model="queryParam.priorityLevel"
                  placeholder="请选择优先级"
                  allow-clear
                >
                  <a-select-option :key="0" :value="0">请选择优先级</a-select-option>
                  <a-select-option v-for="item in priorityLevel" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="6" :sm="24">
                <a-form-item label="创建时间">
                  <a-range-picker
                    format="YYYY-MM-DD"
                    valueFormat="YYYY-MM-DD"
                    v-model="searcDate"
                    @change="changeTime"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="refreshList(1)">查询</a-button>
                <a-button style="margin-left: 8px" @click="resetSearchForm()">重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="addMission" v-if="currentUserData.info.isMissionAdd > 0">添加</a-button>
      </div>
      <a-table
        ref="table"
        size="default"
        rowKey="keyId"
        :key="missionDataKey"
        :loading="missionDataLoading"
        :pagination="paginationSet"
        :columns="columns"
        :dataSource="missionData"
        :defaultExpandAllRows="defaultExpandAllRows"
      >
        <span slot="progress" slot-scope="text, record">
          <a-progress
            :strokeColor="{'0%': '#108ee9','100%': '#87d068' }"
            :percent="record.progress"
          />
        </span>
        <span slot="priorityLevelArr" slot-scope="text, record">
          <a-tag color="lime" v-if="record.priorityLevel === 1">{{ record.priorityLevelStr }}</a-tag>
          <a-tag color="cyan" v-else-if="record.priorityLevel === 2">{{ record.priorityLevelStr }}</a-tag>
          <a-tag color="orange" v-else-if="record.priorityLevel === 3">{{ record.priorityLevelStr }}</a-tag>
          <a-tag color="red" v-else>{{ record.priorityLevelStr }}</a-tag>
        </span>
        <span slot="statusStr" slot-scope="text, record">
          <a-tag color="#595959" v-if="record.status === 0">{{ record.statusStr }}</a-tag>
          <a-tag color="#108ee9" v-else-if="record.status === 10">{{ record.statusStr }}</a-tag>
          <a-tag color="#2db7f5" v-else-if="record.status === 20">{{ record.statusStr }}</a-tag>
          <a-tag color="#87d068" v-else-if="record.status === 30">{{ record.statusStr }}</a-tag>
          <a-tag color="#87d068" v-else>{{ record.statusStr }}</a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="showMissionInfo(record)">详情</a>
          <a-divider type="vertical" />
          <a @click="editMission(record)" :disabled="record.config.isEdit===0" v-if="currentUserData.info.isMissionAdd > 0">编辑</a>
          <a-divider type="vertical" v-if="currentUserData.info.isMissionAdd > 0" />
          <a @click="addSubMission(record)" :disabled="record.config.isAddSub===0" v-if="currentUserData.info.isMissionAdd > 0">子任务</a>
          <a-divider type="vertical" v-if="currentUserData.info.isMissionAdd > 0" />
          <a @click="allocation(record)" :disabled="record.config.isConfig===0">分配</a>
          <a-divider type="vertical" />
          <a @click="dealMiss(record)" :disabled="record.status===40 || record.config.isOperate===0">操作</a>
          <a-divider type="vertical" />
          <a-dropdown>
            <a-menu slot="overlay">
              <a-menu-item v-if="record.status !== 40">
                <a-popconfirm title="是否要作废此任务吗？" :disabled="record.status > 0 || record.config.isCancel===0" @confirm="cancelMissionApi(record.id)">
                  <a v-if="record.status > 0 || (record.config.isCancel===0)">作废</a>
                  <a style="color: #1890ff;" v-else>作废</a>
                </a-popconfirm>
              </a-menu-item>
              <a-menu-item v-else>
                <a-popconfirm title="是否要作恢复任务吗？" @confirm="recoverMissionApi(record.id)">
                  <a style="color: #1890ff;">恢复</a>
                </a-popconfirm>
              </a-menu-item>
              <a-menu-item>
                <a-popconfirm title="是否要删除此任务吗？" :disabled="record.status > 0 || record.config.isDel===0" @confirm="delMissionApi(record.id)">
                  <a v-if="record.status > 0 || (record.config.isDel===0)">删除</a>
                  <a style="color: #1890ff;" v-else>删除</a>
                </a-popconfirm>
              </a-menu-item>
              <!--a-menu-item><a @click="showMissionInfo(record)" style="color: #1890ff;">详情</a></a-menu-item-->
            </a-menu>
            <a>更多<a-icon type="down"/></a>
          </a-dropdown>
        </span>
      </a-table>
      <see-mission ref="seemission" @refreshData="refreshData"></see-mission>
      <mission-info ref="missioninfo" @ok="handleOk" :priorityLevel="priorityLevel"></mission-info>
      <mission-distribute ref="missiondistribute" @ok="handleOk"></mission-distribute>
      <deal-mission ref="dealmission" @ok="handleOk" :statusList="statusList"></deal-mission>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { STable } from '@/components'
import {
  cancelMission,
  delMission,
  missionCondition,
  missionInfo,
  missionList,
  recoverMission
} from '@/api/mission'
import { searchAdminUser } from '@/api/user'
import SeeMission from '@/views/mission/components/SeeMission'
import MissionInfo from '@/views/mission/components/MissionInfo'
import MissionDistribute from '@/views/mission/components/MissionDistribute'
import DealMission from '@/views/mission/components/DealMission'
const columns = [
  {
    title: '任务名称',
    dataIndex: 'title'
  },
  {
    title: '完成进度',
    dataIndex: 'progress',
    scopedSlots: { customRender: 'progress' }
  },
  {
    title: '优先级',
    dataIndex: 'priorityLevelArr',
    scopedSlots: { customRender: 'priorityLevelArr' }
  },
  {
    title: '执行人',
    dataIndex: 'workerName'
  },
  {
    title: '创建人',
    dataIndex: 'creatorName'
  },
  {
    title: '状态',
    dataIndex: 'statusStr',
    scopedSlots: { customRender: 'statusStr' }
  },
  {
    title: '创建时间',
    width: '180px',
    dataIndex: 'createTime'
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'MissionList',
  components: {
    STable,
    SeeMission,
    MissionInfo,
    MissionDistribute,
    DealMission
  },
  data () {
    this.columns = columns
    return {
      current: 0,
      distributeVisible: false,
      visible: false,
      missionInfoVisible: false,
      confirmLoading: false,
      form: this.$form.createForm(this, { name: 'addMissionFrom' }),
      distributefrom: this.$form.createForm(this, { name: 'configMissionFrom' }),
      mdl: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },

      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {
        keyId: 0,
        title: '',
        status: -1,
        priorityLevel: 0,
        startDate: '',
        endDate: '',
        pageNo: 1,
        pageSize: 10
      },
      statusList: [],
      priorityLevel: [],
      mission: {},
      checkParams: {
        titleStatus: 'success',
        titleStatusMsg: null
      },
      missionInfo: {
        workerId: ''
      },
      userMission: {},
      adminUser: [],
      workerId: '',
      missionData: [],
      paginationSet: {
        onChange: page => this.onChange(page),
        onShowSizeChange: (current, pageSize) => this.showSizeChange(current, pageSize),
        showTotal: (total) => `共 ${total} 条`,
        showSizeChanger: true,
        showQuickJumper: false,
        defaultCurrent: 1,
        pageSize: 10,
        total: 0,
        current: 1,
        hideOnSinglePage: false
      },
      defaultExpandAllRows: true,
      missionDataLoading: false,
      missionDataKey: '',
      searcDate: [],
      currentUserData: {}
    }
  },
  filters: {
  },
  created () {
    this.missionConditionApi()
    this.searchAdminUserApi()
    this.missionListApi()
    this.currentUserData = this.$store.state.user
    console.log('currentUserData', this.currentUserData)
  },
  methods: {
    refreshList (isNew = 0) {
      if (isNew > 0) {
        this.paginationSet.pageNo = 1
      }
      this.missionListApi()
    },
    handleOk () {
      this.refreshList(0)
    },
    missionListApi () {
      const _this = this
      _this.missionDataLoading = true
      missionList(_this.queryParam).then((res) => {
        if (res.errorCode === 0) {
          _this.missionData = res.result.data
          _this.paginationSet.total = res.result.totalCount
          _this.paginationSet.current = res.result.pageNo
          _this.paginationSet.pageSize = res.result.pageSize
          _this.missionDataKey = res.timestamp
        }
        _this.missionDataLoading = false
      }).catch((err) => {
        _this.missionDataLoading = false
        console.log(err)
      })
    },
    onChange (pageNumber) {
      this.paginationSet.defaultCurrent = pageNumber
      this.paginationSet.current = pageNumber
      this.queryParam.pageNo = pageNumber
      this.missionListApi()
    },
    showSizeChange (current, pageSize) {
      this.paginationSet.defaultCurrent = 1
      this.paginationSet.current = 1
      this.queryParam.pageNo = 1
      this.paginationSet.pageSize = pageSize
      this.queryParam.pageSize = pageSize
      this.missionListApi()
    },
    missionConditionApi () {
      const _this = this
      missionCondition().then((res) => {
        if (res.errorCode === 0) {
          _this.statusList = res.result.statusList
          _this.priorityLevel = res.result.priorityLevel
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    resetSearchForm () {
      this.queryParam = {
        keyId: 0,
        title: '',
        status: -1,
        priorityLevel: 0,
        startDate: '',
        endDate: '',
        pageNo: 1,
        pageSize: 10
      }
      this.searcDate = []
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    addMission () {
      this.mission = {
        keyId: 0,
        id: '',
        title: '',
        desc: '',
        priorityLevel: 1,
        planTime: ['', ''],
        parentTitle: '',
        parentId: '',
        parentKeyId: 0,
        originalId: '',
        originalKeyId: 0
      }
      this.$refs.missioninfo.ininData(this.mission)
    },
    editMission (data) {
      this.mission = {
        keyId: data.keyId,
        id: data.id,
        title: data.title,
        desc: data.desc,
        priorityLevel: data.priorityLevel,
        planTime: [data.planStartTime, data.planEndTime],
        parentTitle: '',
        parentId: data.parentId,
        parentKeyId: data.parentKeyId,
        originalId: data.originalId,
        originalKeyId: data.originalKeyId
      }
      if (data.parentInfo.keyId > 0) {
        this.mission.parentTitle = data.parentInfo.title
      }
      this.$refs.missioninfo.ininData(this.mission)
    },
    addSubMission (data) {
      this.mission = {
        keyId: 0,
        id: '',
        title: data.title + '-子任务',
        desc: '',
        priorityLevel: data.priorityLevel,
        planTime: [data.planStartTime, data.planEndTime],
        parentTitle: data.title,
        parentId: data.id,
        parentKeyId: data.keyId,
        originalId: data.originalId,
        originalKeyId: data.originalKeyId
      }
      if (data.originalKeyId === 0) {
        this.mission.originalId = data.id
        this.mission.originalKeyId = data.keyId
      }
      this.$refs.missioninfo.ininData(this.mission)
    },
    delMissionApi (id) {
      const _this = this
      delMission({ id: id }).then((res) => {
          if (res.errorCode === 0) {
            _this.$message.success('操作成功')
            _this.refreshList()
          } else {
            _this.$message.error(res.errorMsg)
          }
      }).catch((err) => {
        console.log(err)
      })
    },
    cancelMissionApi (id) {
      const _this = this
      cancelMission({ id: id }).then((res) => {
        if (res.errorCode === 0) {
          _this.$message.success('操作成功')
          _this.refreshList()
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    recoverMissionApi (id) {
      const _this = this
      recoverMission({ id: id }).then((res) => {
        if (res.errorCode === 0) {
          _this.$message.success('操作成功')
          _this.refreshList()
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    allocation (data) {
      this.$refs.missiondistribute.ininData(data)
    },
    missionInfoApi (keyId) {
      const _this = this
      missionInfo({ keyId: keyId }).then((res) => {
        if (res.errorCode === 0) {
          _this.missionInfo = res.result.mission
          _this.userMission = res.result.userMission
          _this.missionInfo.workerKeyId = 0
          if (_this.userMission.workerKeyId) {
            _this.missionInfo.workerKeyId = _this.userMission.workerKeyId
          }
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    searchAdminUserApi () {
      const _this = this
      searchAdminUser({ searchName: '' }).then((res) => {
        if (res.errorCode === 0) {
          _this.adminUser = res.result.data
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    changeTime (e) {
      this.queryParam.startDate = e[0] ? e[0] : ''
      this.queryParam.endDate = e[1] ? e[1] : ''
      this.searcDate = e
      console.log('changeTime', e)
    },
    showMissionInfo (data) {
      this.$refs.seemission.ininData(data.keyId, data.originalKeyId)
    },
    refreshData (e) {
      console.log(e)
    },
    dealMiss (data) {
      this.$refs.dealmission.ininData(data, 1)
    }
  },
  watch: {
  }
}
</script>
